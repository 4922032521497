import { ProjectConfig } from 'app.core/common/module/projectConfigs';
import HeaderLinkareer from 'app.core/linkareer/component/Header/HeaderLinkareer';
import FooterLinkareer from 'app.core/linkareer/component/Footer/FooterLinkareer';
import { LinkareerSeoConfig } from 'app.core/linkareer/component/seo/SEOLinkareer';

const linkareerSeo = new LinkareerSeoConfig();

export const LinkareerHosts = ['cbt.linkareer.com', 'cbt.lnkr.cc', 'local-cbt.lnkr.cc'];

export const LinkareerProject: { [key in 'production' | 'development' | 'test']: ProjectConfig } = {
  development: {
    projectName: 'Linkareer',
    projectNameKR: '링커리어',
    clientBaseUrl: 'https://lnkr.cc/',
    handleLoginRedirect: (url?: string) => {
      if (url) return `https://lnkr.cc/login?next=${encodeURIComponent(url)}`;
      return `https://lnkr.cc/login`;
    },
    logoutRedirectUri: 'https://gateway-api.lnkr.cc/logout',
    apiBaseUrl: 'https://gateway-api.lnkr.cc',
    apiBaseProxyUrl: 'https://api.lnkr.cc/academy',
    idp: 'linkareer',
    logo: '/images/logo/linkareer/logo.svg',
    logoImagePng: '/images/logo/linkareer/logo(800X800).png',
    kakaoServiceKey: '171d15c1a52fc8d0eb38550f93c1cbee', // 로컬 환경에서는 카카오 developer를 통한 공유하기가 잘못된 요청 팝업으로 동작됩니다
    googleTagManagerId: 'G-D5TG9CDEF8',
    header: HeaderLinkareer,
    footer: FooterLinkareer,
    defaultSeo: () => linkareerSeo.defaultSeo() || {},
    examListSeo: (type) => linkareerSeo.examListSeo(type),
    examDetailSeo: (data) => linkareerSeo.examDetailSeo(data),
    examTypeSelectionSeo: (data) => linkareerSeo.examTypeSelectionSeo(data),
    cbtOneQuestionTypeSeo: (data) => linkareerSeo.cbtOneQuestionTypeSeo(data),
    cbtPracticeTypeSeo: (data) => linkareerSeo.cbtPracticeTypeSeo(data),
    examResultSeo: (data) => linkareerSeo.examResultSeo(data),
    examSolutionSeo: (data) => linkareerSeo.examSolutionSeo(data),
    myCBTArchiveSeo: () => linkareerSeo.myCBTArchiveSeo(),
    myCBTResultSeo: () => linkareerSeo.myCBTResultSeo(),
    myCBTNoteSeo: () => linkareerSeo.myCBTNoteSeo(),
  },

  production: {
    projectName: 'Linkareer',
    projectNameKR: '링커리어',
    clientBaseUrl: 'https://linkareer.com/',
    handleLoginRedirect: (url?: string) => {
      if (url) return `https://linkareer.com/login?next=${encodeURIComponent(url)}`;
      return 'https://linkareer.com/login';
    },
    logoutRedirectUri: 'https://api.linkareer.com/logout',
    apiBaseUrl: 'https://api.linkareer.com',
    apiBaseProxyUrl: 'https://api.linkareer.com/academy',
    idp: 'linkareer',
    logo: '/images/logo/linkareer/logo.svg',
    logoImagePng: '/images/logo/linkareer/logo(800X800).png',
    kakaoServiceKey: '171d15c1a52fc8d0eb38550f93c1cbee',
    googleTagManagerId: 'G-D5TG9CDEF8',
    header: HeaderLinkareer,
    footer: FooterLinkareer,
    defaultSeo: () => linkareerSeo.defaultSeo() || {},
    examListSeo: (type) => linkareerSeo.examListSeo(type),
    examDetailSeo: (data) => linkareerSeo.examDetailSeo(data),
    examTypeSelectionSeo: (data) => linkareerSeo.examTypeSelectionSeo(data),
    cbtOneQuestionTypeSeo: (data) => linkareerSeo.cbtOneQuestionTypeSeo(data),
    cbtPracticeTypeSeo: (data) => linkareerSeo.cbtPracticeTypeSeo(data),
    examResultSeo: (data) => linkareerSeo.examResultSeo(data),
    examSolutionSeo: (data) => linkareerSeo.examSolutionSeo(data),
    myCBTArchiveSeo: () => linkareerSeo.myCBTArchiveSeo(),
    myCBTResultSeo: () => linkareerSeo.myCBTResultSeo(),
    myCBTNoteSeo: () => linkareerSeo.myCBTNoteSeo(),
  },

  test: {
    projectName: 'Linkareer',
    projectNameKR: '링커리어',
    clientBaseUrl: 'https://lnkr.cc/',
    handleLoginRedirect: (url?: string) => {
      if (url) return `https://lnkr.cc/login?next=${encodeURIComponent(url)}`;
      return `https://lnkr.cc/login`;
    },
    logoutRedirectUri: 'https://gateway-api.lnkr.cc/logout',
    apiBaseUrl: 'https://gateway-api.lnkr.cc',
    apiBaseProxyUrl: 'https://api.lnkr.cc/academy',
    idp: 'linkareer',
    logo: '/images/logo/linkareer/logo.svg',
    logoImagePng: '/images/logo/linkareer/logo(800X800).png',
    kakaoServiceKey: '171d15c1a52fc8d0eb38550f93c1cbee',
    googleTagManagerId: 'G-D5TG9CDEF8',
    header: HeaderLinkareer,
    footer: FooterLinkareer,
    defaultSeo: () => linkareerSeo.defaultSeo() || {},
    examListSeo: (type) => linkareerSeo.examListSeo(type),
    examDetailSeo: (data) => linkareerSeo.examDetailSeo(data),
    examTypeSelectionSeo: (data) => linkareerSeo.examTypeSelectionSeo(data),
    cbtOneQuestionTypeSeo: (data) => linkareerSeo.cbtOneQuestionTypeSeo(data),
    cbtPracticeTypeSeo: (data) => linkareerSeo.cbtPracticeTypeSeo(data),
    examResultSeo: (data) => linkareerSeo.examResultSeo(data),
    examSolutionSeo: (data) => linkareerSeo.examSolutionSeo(data),
    myCBTArchiveSeo: () => linkareerSeo.myCBTArchiveSeo(),
    myCBTResultSeo: () => linkareerSeo.myCBTResultSeo(),
    myCBTNoteSeo: () => linkareerSeo.myCBTNoteSeo(),
  },
};
