import React from 'react';
import {
  LinkareerHosts,
  LinkareerProject,
} from 'app.core/linkareer/constant/linkareerProjectConfig';
import { LocalProject } from 'app.core/local/constant/localProjectConfig';
import { NextSeoProps, DefaultSeoProps } from 'next-seo';
import { TExamsInfo } from 'app.type/examsInfo';

type CBT_ENV = 'development' | 'production' | 'test';

const currentEnv: CBT_ENV = (process.env.CBT_ENV as CBT_ENV) || 'development';

export const hosts = ['local-cbt.link', 'cbt.local-cbt.link', ...LinkareerHosts] as const;
export type Host = (typeof hosts)[number];

export type ProjectConfig = {
  projectName: string;
  projectNameKR: string;
  handleLoginRedirect: (url?: string) => string;
  logoutRedirectUri: string;
  clientBaseUrl: string;
  apiBaseUrl: string;
  apiBaseProxyUrl: string;
  idp: string;
  header: React.FC;
  footer: React.FC;
  googleTagManagerId?: string;
  logo: string; //svg 파일
  logoImagePng: string; //png 파일 (카카오 공유하기 용, 400*400 ~ 800*800 사이즈)
  kakaoServiceKey: string;
  // seo 관련
  defaultSeo: () => DefaultSeoProps;
  examListSeo?: (data?: string) => NextSeoProps;
  examDetailSeo?: (data?: TExamsInfo) => NextSeoProps;
  examTypeSelectionSeo?: (data?: any) => NextSeoProps;
  cbtOneQuestionTypeSeo?: (data?: any) => NextSeoProps;
  cbtPracticeTypeSeo?: (data?: any) => NextSeoProps;
  examResultSeo?: (data?: any) => NextSeoProps;
  examSolutionSeo?: (data?: any) => NextSeoProps;
  myCBTArchiveSeo?: (data?: NextSeoProps) => NextSeoProps;
  myCBTResultSeo?: (data?: NextSeoProps) => NextSeoProps;
  myCBTNoteSeo?: (data?: NextSeoProps) => NextSeoProps;
};

type ProjectConfigs = {
  [key in Host]: ProjectConfig;
};

export const linkareerProjectConfigs: ProjectConfigs = {
  'cbt.linkareer.com': LinkareerProject[currentEnv] as ProjectConfig, // PRODUCTION
  'cbt.lnkr.cc': LinkareerProject[currentEnv] as ProjectConfig, // LOCAL
  'local-cbt.lnkr.cc': LinkareerProject[currentEnv] as ProjectConfig, // LOCAL
};

export const projectConfigs: ProjectConfigs = {
  // ProjectConfig
  'local-cbt.link': LocalProject[currentEnv] as ProjectConfig, // LOCAL
  'cbt.local-cbt.link': LocalProject[currentEnv] as ProjectConfig, // TEST
  ...linkareerProjectConfigs,
};
