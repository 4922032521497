import { ProjectConfig } from 'app.core/common/module/projectConfigs';
import { default as localHeader } from 'app.core/local/component/Header';
import { default as localFooter } from 'app.core/local/component/Footer';

export const LocalProject: { [key in 'production' | 'development' | 'test']: ProjectConfig } = {
  development: {
    projectName: 'local',
    projectNameKR: '로컬',
    idp: 'local',
    clientBaseUrl: 'https://www.local-cbt.link/',
    handleLoginRedirect: (url?: string) => {
      return 'https://local-cbt.link/login';
    },
    logoutRedirectUri: 'https://local-cbt.link/api/auth/logout',
    apiBaseUrl: '',
    apiBaseProxyUrl: 'https://local-cbt.link/api/proxy',
    logo: '/images/logo/local/logo.svg',
    logoImagePng: '',
    header: localHeader,
    footer: localFooter,
    // SeoConfig: SEOLocal,
    kakaoServiceKey: '',
    defaultSeo: () => {
      return {};
    },
  },

  production: {
    projectName: 'local',
    projectNameKR: '로컬',
    idp: 'local',
    clientBaseUrl: 'https://www.local-cbt.link/',
    handleLoginRedirect: (url?: string) => {
      return 'https://local-cbt.link/login';
    },
    logoutRedirectUri: 'https://local-cbt.link/api/auth/logout',
    apiBaseUrl: '',
    apiBaseProxyUrl: 'https://local-cbt.link/api/proxy',
    logo: '/images/logo/local/logo.svg',
    logoImagePng: '',
    header: localHeader,
    footer: localFooter,
    // SeoConfig: SEOLocal,
    kakaoServiceKey: '',
    defaultSeo: () => {
      return {};
    },
  },

  test: {
    projectName: 'local',
    projectNameKR: '로컬',
    idp: 'local',
    clientBaseUrl: 'https://www.local-cbt.link/',
    handleLoginRedirect: (url?: string) => {
      return 'https://local-cbt.link/login';
    },
    logoutRedirectUri: 'https://local-cbt.link/api/auth/logout',
    apiBaseUrl: '',
    apiBaseProxyUrl: 'https://local-cbt.link/api/proxy',
    logo: '/images/logo/local/logo.svg',
    logoImagePng: '',
    header: localHeader,
    footer: localFooter,
    // SeoConfig: SEOLocal,
    kakaoServiceKey: '',
    defaultSeo: () => {
      return {};
    },
  },
};
